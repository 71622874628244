import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import FeaturedServicesStyles from '../styles/FeaturedServices.module.sass'

/**
 * Display a section with Featured Services
 */
const FeaturedServices = () => {
  const data = useStaticQuery(posts)

  return (
    <div
      className={`container has-text-left ${FeaturedServicesStyles.featuredServices}`}
    >
      <div className={`columns is-multiline ${FeaturedServicesStyles.columns}`}>
        {data.allMdx.edges.map(edge => {
          return (
            <div
              key={edge.node.id}
              className={`column is-half-tablet is-one-quarter-desktop ${FeaturedServicesStyles.column}`}
            >
              <Link to={`/${edge.node.fields.slug}`} className="has-text-grey">
                <header className={FeaturedServicesStyles.header}>
                  {edge.node.fields.image ? (
                    <Img fluid={edge.node.fields.image.childImageSharp.fluid} alt={edge.node.frontmatter.title} />
                  ) : null}
                  <span className="title">
                    {edge.node.frontmatter.shortenedTitle ||
                      edge.node.frontmatter.title}
                  </span>
                </header>
                <div className="description">
                  {edge.node.frontmatter.description}
                </div>
                <span
                  className={`button is-primary ${FeaturedServicesStyles.serviceButton}`}
                >
                  From&nbsp;<strong>&euro;{edge.node.frontmatter.price}</strong></span>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FeaturedServices

export const posts = graphql`
  {
    allMdx(
      filter: {
        fields: { featuredPosition: { gt: 0 } }
        frontmatter: { status: { eq: "publish" } }
      }
      sort: { fields: fields___featuredPosition }
      limit: 4
    ) {
      edges {
        node {
          id
          fields {
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontmatter {
            description
            title
            shortenedTitle
            price
          }
        }
      }
    }
  }
`
