import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'
import FeaturedServices from '../components/FeaturedServices'
import Phone from '../components/shortcodes/Phone'
import Anchor from '../components/shortcodes/Anchor'
import HomeStyles from '../styles/Home.module.sass'
import CMSPreviewStyles from '../styles/CMS.module.sass'

/**
 * Display the index page template
 *
 * @param {string} title
 * @param {string} intro
 * @param {string} featuredServicesTitle
 * @param {string} aboveTitle
 * @param {string} subtitle
 * @param {string} featuredServicesIntro
 * @param {object} image
 */
const IndexTemplate = ({
  title,
  h2Title,
  pageIdentifier,
  seoTitle,
  seoDescription,
  image,
  intro,
  featuredServicesTitle,
  aboveTitle,
  subtitle,
  featuredServicesIntro,
  body,
  disableMdx,
  imageAnchor,
  imageAlt,
}) => {
  return (
    <Layout
      className={`is-page-${pageIdentifier}`}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
    >
      <Hero
        title={title}
        special="true"
        image={image}
        h2Title={h2Title}
        anchor={imageAnchor}
        imageAlt={imageAlt || title}
        subtitle={subtitle}
        aboveTitle={aboveTitle}
      />

      <div className="container is-semi-narrow is-clearfix">
        <ContactForm className={HomeStyles.form} />
        <div className={`intro content is-float-sibling ${HomeStyles.intro}`}>
          <div dangerouslySetInnerHTML={{ __html: intro }} />
        </div>
      </div>

      <div
        className={`container content is-semi-narrow has-text-centered ${HomeStyles.featuredServicesHolder}`}
      >
        <Anchor id="immersion-heater-repair" />

        <h2 className="title is-2">{featuredServicesTitle}</h2>

        {featuredServicesIntro && (
          <p className="is-narrow">{featuredServicesIntro}</p>
        )}
        
        <FeaturedServices />
        <a
          className={`button is-link is-large ${HomeStyles.buttonViewAllPrices}`}
          href="#immersion-heater-repair-prices"
        >
          View All Prices
        </a>
        <span className="emphasised-text">or CALL <Phone /><br /> for an immersion heater repair quote</span>
      </div>

      <div className="container content content-body is-narrow">
        {/* Markdown fallback for CMS Previews */}
        {disableMdx === true || disableMdx === 'true' ? (
          body
        ) : (
          <MDXRenderer>{body}</MDXRenderer>
        )}
      </div>
    </Layout>
  )
}

export { IndexTemplate }

/**
 * Create Netlify CMS-compatible Preview Component
 */
const IndexPage = () => {
  const data = useStaticQuery(post)

  // Clean up multiline Intro
  const formattedIntro = data.mdx.frontmatter.intro
    .split(`\n`)
    .map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
    .join(``)
    .replace(/<p><\/p>/g, ``)

  // Split imageAnchor classes when multiple
  const formattedImageAnchor = data.mdx.frontmatter.imageAnchor
    ? data.mdx.frontmatter.imageAnchor.toString().replace(',', ' ')
    : ''

  return (
    <IndexTemplate
      title={data.mdx.frontmatter.title}
      h2Title={data.mdx.frontmatter.h2Title}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      seoTitle={data.mdx.frontmatter.seoTitle}
      seoDescription={data.mdx.frontmatter.seoDescription}
      image={data.mdx.fields.image}
      imageAlt={data.mdx.frontmatter.imageAlt}
      intro={formattedIntro}
      featuredServicesTitle={data.mdx.frontmatter.featuredServicesTitle}
      aboveTitle={data.mdx.frontmatter.aboveTitle}
      subtitle={data.mdx.frontmatter.subtitle}
      featuredServicesIntro={data.mdx.frontmatter.featuredServicesIntro}
      body={data.mdx.body}
      imageAnchor={formattedImageAnchor || ''}
    />
  )
}

export default IndexPage

/**
 * Configure Netlify CMS Editor Preview
 */
const IndexPreview = ({ entry, widgetFor }) => {
  const data = entry.getIn(['data']).toJS()

  if (data) {
    // Clean up multiline Intro
    const formattedIntro = data.intro
      .split(`\n`)
      .map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
      .join(``)
      .replace(/<p><\/p>/g, ``)

    // Split imageAnchor classes when multiple
    const formattedImageAnchor = data.imageAnchor
      ? data.imageAnchor.toString().replace(',', ' ')
      : ''

    return (
      <IndexTemplate
        title={data.title || 'Title goes here...'}
        pageIdentifier={`index ${CMSPreviewStyles.cms}`}
        seoTitle=""
        seoDescription=""
        intro={formattedIntro || 'Intro goes here...'}
        featuredServicesTitle={
          data.featuredServicesTitle || 'Featured Services Title...'
        }
        aboveTitle={
          data.aboveTitle || "Above title..."
        }
        subtitle={
          data.subtitle || 'Subtitle...'
        }
        featuredServicesIntro={
          data.featuredServicesIntro || 'Featured Services Intro goes here...'
        }
        image={data.image}
        imageAnchor={formattedImageAnchor || ''}
        body={widgetFor('body')}
        disableMdx="true"
      />
    )
  } else {
    return <div>Loading...</div>
  }
}

export { IndexPreview }

export const post = graphql`
  {
    mdx(frontmatter: { templateKey: { eq: "index" } }) {
      body
      rawBody
      frontmatter {
        title
        intro
        featuredServicesTitle
        aboveTitle
        subtitle
        featuredServicesIntro
        seoTitle
        seoDescription
        h2Title
        imageAnchor
        imageAlt
      }
      fields {
        pageIdentifier
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`